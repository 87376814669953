.trackboard-head {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #393939;
  margin: 0 0 8px;
}
.ecom-deep-dive{
  text-align: left;
}
.ecom-card-content{
  padding: 10px;

  .ecom-card-main{
    background: white;
    border-radius: 10px;
    padding: 14px;
    height: 340px;
    overflow: auto;


      h3{
          color: black;
          font-weight: lighter;
          font-size: 16px;
      }
      .break-line{
        border-bottom: 1px solid lightgray;
        margin-top: 10px;
        margin-bottom: -3px;
      }
      p{
          margin-bottom: 3px;
      }
      .mb-0{
        margin-bottom: 0;
      }
  }
}

.que-header{
font-weight: 600;
color: black;
margin-top: 12px;
}

.trackboard-info {
  text-align: center;
  width: 100%;
  background: #F2F2F2;
  border-radius: 4px;
  padding: 4px 10px;
  font-size: 2.4vh;
  color: #000;
  position: relative;

  p {
    margin: 0;
  }
  a {
    top: 50%;
    right: 10px;
    position: absolute;
    transform: translateY(-50%);
  }
}

.tooltip-content {
  margin: 16px;
  display: flex;
  flex-direction: column;
}

.tooltip-label {
  color: '#FAB518';
  font-size: 14px;
  font-weight: 700;
}

.ant-col.diagnostic-card {
  display: flex;
  height: 343px;
  padding: 19px 24px 17px 10px;
  gap: 25px;
  border-radius: 8px;
  background: #FFF;
  max-width: 750px;
}

.card-image-container {
  width: 100%;
  max-width: 240px;
}

.card-image {
  border-radius: 15px;
  width: 100%;
  height: 300px;
}

.card-content {
  text-align: start;
  position: relative;
}

.card-header {
  display: flex;
  align-items: center;
  gap: 3px;
}

.header-icon {
  margin-left: -2px;
}

.header-title {
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
  color: #000;
}

.tooltip-icon {
  height: 13px;
  width: 13px;
}

.card-description {
  font-size: 13px;
}

.card-questions {
  display: flex;
  flex-direction: column;
}

.questions-label {
  font-size: 13px;
  padding-top: 10px;
  color: #525252;
  font-weight: 700;
}

.question {
  font-size: 13px;
}
.card-button-container{
  width: 100%;
}
.card-button {
  width: 100%;
  position: absolute !important;
  bottom: 7px !important;
  background: #0000A0 !important;
  color: white !important;
  font-size: 14px !important;
  border-radius: 4px !important;
}
.custom-container {
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
}

.diagnostics-label {
  color: #FAB518;
  font-size: 14px;
  font-weight: 700;
}
.be-available-label {
  color: #39A5DB;
  font-size: 14px;
  font-weight: 700;
}
.be-visible-label {
  color: #FF32A0;
  font-size: 14px;
  font-weight: 700;
}
.be-selected-label {
  color: #00C853;
  font-size: 14px;
  font-weight: 700;
}

.description,
.source {
  font-size: 14px;
  font-weight: 400;
}
.card-main-container{
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.ant-row.card-row{
  display: grid;
  gap: 18px;
  grid-template-columns: auto auto;
}
.card-button-disable{
  width: 100%;
  position: absolute !important;
  bottom: 7px !important;
  font-size: 14px !important;
  border-radius: 4px !important;
}