.beSelected-card{
    background-color: white;
    margin-bottom: 16px;
  
    &::before{
        content: "";
        height: fit-content;
        background: #06a906;
        width: 9px;
        min-height: 50px;
        border-radius: 5px;
        left: -10px;
        position: relative;
        }
    div{
        margin: 10px 0 0 0;
        font-size: 13px;
        span img{
            margin-top: -3px;
            margin-right: 3px;
        }
        b{
            font-size: 18px;
            font-weight: bolder;
            color: black;
        }
    } 
  }
  .btn-grp{
    background: rgba(234, 234, 234, 0.6);
    padding: 5px;
    border-radius: 5px;
    margin-bottom: 20px;
    
    .ant-radio-button-wrapper:first-child{
        background: transparent;
        border: none;
        border-radius: 5px;
    }
    .ant-radio-button-wrapper:not(:first-child)::before {
        width: 0;
    }
    .ant-radio-button-wrapper:nth-child(2){
        background: transparent;
        border: none;
        border-radius: 5px;
    }
    .ant-radio-button-wrapper:last-child{
        background: transparent;
        border: none;
        border-radius: 5px;
    }
  }
.Selecteddata-card {
    background: #fff;
    border-radius: 12px;
    padding: 20px 10px;
    text-align: center;
    box-shadow: 0px 0px 42.6642px 0px rgba(0, 0, 0, 0.06);

    .Selecteddata-head {
        margin: 0 0 10px;
        padding: 0 16px;
        position: relative;

        h4 {
            color: #000;
            font-size: 15px;
            line-height: 24px;
            margin: 0;
        }
        .d-check {
            top: 0;
            left: 0;
            position: absolute;
        }
        .d-more {
            top: 0;
            right: 0;
            position: absolute;
        }
    }
    .data-value {
        margin-bottom: 15px;
        color: #0000A0;
    }
}
.beSelected-cards{
    font-size: 60px;

    .sentiment_Data{
        font-size: 15px;
        margin-top: -3px;
    }
}
.beSelected-box{
    background: rgba(0,200,83,.09);
    padding: 7px;
    display: flex;
    border-radius: 7px;
    margin-bottom: 10px;

    img{
        margin-left: 5px;
        margin-right: 10px;
        width: 15px;
    }

    h5{
        font-weight: 700;
        font-size: 12px;
        line-height: 18px;
        color: black;
        margin-bottom: 0;

        span{
            color: #00C853;
        }
    }

}
.rating-title{
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: black;
    text-align: center;
    margin: 5 0 12px;
}

.scatterChart{
    .g2-tooltip {
        padding: 5px 12px 5px 12px !important;
        background: black !important;
        color: white !important
    }
}
.reviews-wrap {
    display: flex;
    justify-content: space-between;
    border: 1px solid #E0E0E0;
  
    .review-section-title {
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
      color: #0000A0;
      text-align: center;
      margin: 0 0 12px; 
    }
  
    .rating-wrap {
      flex: 0 0 46.83%;
      border-radius: 10px;
      display: flex;
  
      .rating {
        flex: 0 0 43.86%;
        padding: 0 20px 25px 20px;
  
        .rating-head {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 16px 0;
          border-bottom: 1px solid #E0E0E0;
  
          h5 {
            font-weight: 700;
            font-size: 16px;
            line-height: 22px;
            color: #0000A0;
            margin: 0;
            padding: 0 36px;
          }
  
          .disabled {
            color: #D3D3D3;
            cursor: default;
          }
        }
        .rating-head-c {
          display: flex;
          flex-direction: column;
          align-items: start;
          justify-content: center;
          padding: 16px 0;
          border-bottom: 1px solid #E0E0E0;
  
        }
        // .rating-stats {
        //   padding: 20px 0;
  
        //   ul {
        //     margin: 0;
        //     padding: 0;
        //     list-style: none;
        //     display: flex;
        //     align-items: center;
            
        //     li {
        //       display: flex;
        //       align-items: center;
  
        //       label {
        //         font-weight: 400;
        //         font-size: 14px;
        //         line-height: 18px;
        //         color: #424242;
        //         margin-right: 8px;
        //       }
        //       span {
        //         font-weight: 700;
        //         font-size: 14px;
        //         line-height: 18px;
        //         color: #424242;
        //       }
        //       + li {
        //         margin-left: 50px;
        //       }
        //     }
        //   }
        // }
        .rating-stats {
          display: flex;
          justify-content: space-between;
          margin: 5px 0;
          label {
            margin-right: 5px;
          }
          div {
            display: flex;
            span {
              // margin-left: 5px;
              font-weight: 700;
              .ant-rate {
                color: #fab518
              }
              .ant-rate-star {
                margin-right: 4px;
              }
            }
          }
          
  
        }
        .rating-main-box{
            padding-right: 32px !important;
            border-right: 1px solid #E0E0E0;
        }
        .rating-chart-wrap {
          background: #FFFFFF;
          border-radius: 10px;
          padding: 16px 22px;
  
          .ratings-data{
            display: flex;
            justify-content: space-between;
            margin-bottom: 15px;
          }
          .rating-chart-head {
            font-weight: 700;
            font-size: 14.2214px;
            line-height: 19px;
            text-align: center;
            color: #000000;
            margin: 0 0 26px;
          }
          .consumer-legends {
            display: flex;
            margin-bottom: 16px;

            .consumer-legend {
              display: flex;
              margin-right: 16px;
              
              span {
                display: flex;
                margin-right: 5px;
                width: 24px;
                height: 24px;
                border-radius: 50%;
                justify-content: center;
                align-items: center;

                &.positive {
                  background: #00cc3e;
                }
                &.neutral {
                  background: #ffb100;
                }
                &.negative {
                  background: #ff5000;
                }
              }
              p {
                margin-bottom: 0;
              }
            }
          }
        }
      }
      .themes-box{
          background: #FFFFFF;
          border-radius: 10px;
          padding: 16px 22px;
          box-shadow: 0px 0px 42.664215087890625px 28.442808151245117px rgba(0, 0, 0, 0.02);
          margin-top: 20px;
      }

      
.top-keywords {
    margin: 0 0 20px;
  
    h5 {
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: #424242;
      margin: 0 0 12px;
    }
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      display: grid;
      grid-template-columns: auto auto;
  
      li {
        display: inline-block;
        border-radius: 8px;
        padding: 8px 12px;
        background: rgba(0, 0, 160, 0.05);
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
        color: #0000A0;
        margin: 0 8px 12px 0;
      }
    }
  }
      .analysis {
        padding: 16px 22px;
        border-left: 1px solid #E0E0E0;
        flex: 1 1 auto;
  
        .sentiment-box {
          padding: 22px 32px 26px 32px;
          border-radius: 10px;
          text-align: center;
          margin: 0 0 20px;
  
          img {
            width: 33px;
            margin: 0 0 8px;
          }
          h5 {
            font-weight: 700;
            font-size: 14px;
            line-height: 18px;
            margin: 0 0 8px;
          }
          p {
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            color: #8E8E8E;
            margin: 0;
          }
  
          &.positive {
            background: rgba(0, 200, 83, .09);
            h5 {
              color: #00C853;
            }
          }
          &.negative {
            background: rgba(255, 239, 231);
            h5 {
              color: #F65F0A;
            }
          }
          &.neutral {
            background: rgba(254, 242, 214);
            h5 {
              color: #fab518;
            }
          }
        }
      }
      .analysis-list {
        padding: 16px;
        border-left: 1px solid #E0E0E0;
        // flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        // align-items: space-evenly;
  
        .sentiment-box {
          display: flex;
          align-items: center;
          padding: 5px 10px;
          border-radius: 8px;
          text-align: center;
          margin: 0 0 15px;
  
          img {
            width: 15px;
            margin-right: 5px;
          }
          h5 {
            font-weight: 700;
            font-size: 12px;
            line-height: 18px;
            // margin: 0 0 8px;
          }
          p {
            font-weight: 400;
            font-size: 14px;
            line-height: 14px;
            color: #8E8E8E;
            margin: 0;
          }
  
          &.positive {
            background: rgba(0, 200, 83, .09);
            span {
              color: #00C853;
            }
          }
          &.negative {
            background: rgba(255, 239, 231);
            span {
              color: #F65F0A;
            }
          }
          &.neutral {
            background: rgba(254, 242, 214);
            span {
              color: #fab518;
            }
          }
        }
      }
    }
    .traffic {
      flex: 0 0 46.93%;
      border-radius: 10px;
      padding: 16px;
      .review-section{
        display: flex;
        align-items: baseline;
        justify-content: space-between;
        margin-top: -12px;
        padding: 10px;
      }
      .filter-box{
        display: flex;
        justify-content: space-around;
         padding: 6px;
          border-radius: 10px;
          text-align: center;
          align-items: center;  
          margin: 0 0 20px;
          background: #EEEEEE;
          .filter-inner{
            display: flex;
            flex-direction: column; 
          }
      }
      .review-list{
        overflow: auto;
        max-height: 590px !important;
      }
      .review-box{
        height: auto;
        padding: 10px 14px 0px 14px;
        border-radius: 10px;
        text-align: center;
        margin: 0 0 20px;
        background: #EEEEEE;;
      }
    }
  }
  .export-btn{
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }