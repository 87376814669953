// .inner-card-alerts {
//     background: #fff;
//     border-radius: 10px;
//     display: flex;
//     align-items: center;
//     max-height: 9vh;
//     height: 63px;

//     .symbol{
//         margin-left: 10px;
//     }
//     .card-header {
//       color: #000000;
//       display: flex;
//       padding-top: 12px;
//       font-size: inherit !important;
//       font-weight: 500 !important;
//       justify-content: flex-start !important;
//       padding-bottom: 15px;
//     }
//   }

.inner-card-alerts {
    background: #fff;
    border-radius: 10px;
    display: flex;
    align-items: center;
    padding: 10px;
    margin: 0 0 10px;
  
    .card-symbol {
      padding-right: 12px;
      min-width: 30px;
    }
    .card-title {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #000;
    }
  }
    
  