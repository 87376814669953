img {
  max-width: 100%;
}
h1 {
  font-family: $marsFontExtrabold;
  font-weight: 900;
}
h2,
h3,
h4,
h5,
h6 {
  font-family: $marsFontBold;
  font-weight: 700;
}
h2 {
  font-size: 22px;
  line-height: 26px;
}
body {
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar {
    width: 12px;
    height: 12px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #aaa;
  }
}
.label-block {
  display: block;
  white-space: nowrap;
}

.label-block-export {
  display: block;
  font-size: 11px;
}

.label-select {
  font-size: 12px;
  line-height: 16px;
  color: #9a9a9a;
  margin: 0 0 4px;
}

.custom-table {
  .ant-table {
    color: #000;
    border-radius: 10px;

    table {
      // border-spacing: 8px;
      border-spacing: 2px;
    }
  }
  .ant-table-thead > tr > th {
    color: #ffffff;
    background: #0000a0;
    font-weight: 700;
  }
  .ant-table-tbody > tr > td {
    border: none;
    background: #f0f0f0;
  }
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td,
  .ant-table tfoot > tr > th,
  .ant-table tfoot > tr > td {
    padding: 6px 4px;
    font-size: 12px;
    line-height: 16px;
  }
}

.ant-btn {
  color: #000;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  border-radius: 6px;
  border-color: #0000a0;
  border-width: 2px;
}
.ant-btn-link {
  color: #0000a0;
  border-color: transparent;
}
.ant-btn-primary {
  color: #fff;
}
.ant-divider {
  margin: 12px 0;
  border-top-color: #000;
}

.text-success {
  color: #00c853;
}
.text-danger {
  color: #ec3225;
}
.bg-success {
  background: #009d41;
}
.bg-danger {
  background: #ec3225;
}

.content-body {
  padding: 10px 30px;
  min-height: 280px;  
}


.footer-message {
  .content-body {
    /*This is for special case (only when bottom text)*/
    padding-bottom: 60px;
  }
}
.ant-layout {
  min-height: calc(-12px + 100vh) !important;
  overflow-y: unset !important;
}

.gray-background{
  background: #F2F2F2;
}
.perf-cards{
  max-width: 20%;
  flex: 0 1 20%;
}
.sticky-filter{
  position: sticky;
  top: 68px;
  z-index: 99;
}
.dist-input{
  width: 100px;
  height: 32px;
  border-radius: 2px;
}
.content-none {
  display: none
}

.content-body-report {
  padding: 10px 30px;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  font-weight: 700;
}
.ant-tabs-top > .ant-tabs-nav::before,
.ant-tabs-bottom > .ant-tabs-nav::before,
.ant-tabs-top > div > .ant-tabs-nav::before,
.ant-tabs-bottom > div > .ant-tabs-nav::before {
  border-color: #dcdcdc;
}
.ant-tabs-top > .ant-tabs-nav,
.ant-tabs-bottom > .ant-tabs-nav,
.ant-tabs-top > div > .ant-tabs-nav,
.ant-tabs-bottom > div > .ant-tabs-nav {
  margin: 0 0 24px;
}
.ant-tabs {
  color: rgba(0, 0, 0, 0.85);
}

.chart-hint {
  display: flex;
  align-items: center;

  .chart-line {
    display: flex;
    align-items: center;
    font-size: 11px;
    line-height: 14px;
    color: #393939;
    margin-right: 20px;

    .line-design {
      width: 42px;
      height: 3px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: #000;
      margin-right: 8px;

      &::before {
        content: "";
        width: 9px;
        height: 9px;
        border-radius: 50%;
        background-color: inherit;
      }
      &::after {
        content: "";
        width: 9px;
        height: 9px;
        border-radius: 50%;
        background-color: inherit;
      }

      &.blue {
        background-color: #8080cf;

        &::before,
        &::after {
          background-color: #040442;
        }
      }
      &.pink {
        background-color: #ff32a0;

        &::before,
        &::after {
          background-color: #950050;
        }
      }
      &.green {
        background-color: #098bcb;

        &::before,
        &::after {
          background-color: #098bcb;
        }
      }
      &.yellow {
        background-color: #fab518;

        &::before,
        &::after {
          background-color: #fab518;
        }
      }
      &.red {
        background-color: #f06055;

        &::before,
        &::after {
          background-color: #f06055;
        }
      }
      &.copper {
        background-color: #7a0901;

        &::before,
        &::after {
          background-color: #7a0901;
        }
      }
    }
  }
}
.ant-input {
  padding: 8px;
  background-color: #F8F8F8;
  border-color: #D9D9D9;
  border-radius: 6px;
}
.inline-list {
  display: flex;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;

  > li {
    margin: 0px 6px;
  }
}

.refreshed-on {
  margin: 0 0 10px;
  display: flex;
  align-items: center;
  color: #9a9a9a;
  font-size: 2.4vh;
  padding: 6px 0;
  width: 100%;
  border-bottom: 1px solid #d9d9d9;
  justify-content: space-between;
.refreshLine{
  height: 20px;
  width: 2px;
  background-color: lightgray;
}
  span + span {
    margin-left: 20px;
  }

  .ytd_toggle {
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 2.4vh;
    line-height: 20px;

    .ant-switch{
        display: block;
        background-image: none;
        background-color: #0000a0 !important;
    }
    .switch {
        margin: 0 10px;
    }
    .selected{
        color: #0000a0;
        font-weight: bold;
        background: none;
    }
    .unselected{
        color: #8F8F8F;
    }
  }
}

.refreshed-on-ecom {
  color: #9a9a9a;
  font-size: 12px;
  white-space: nowrap;
}

.nested-breadcrumbs {
  ul {
    list-style: none;
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;

    > li {
      color: #7D7D7D;
      
      + li::before {
          content: ">";
          margin: 0 5px;
      }

      a {
        color: #7D7D7D;
        position: relative;
        text-decoration: underline;
      }
    }
  }

  span + span {
    margin: 0;
    font-weight: 700;
  }
}

// #root {
//   padding-top: 66px;
// }
.ant-select-multiple.ant-select-show-arrow .ant-select-selector,
.ant-select-multiple.ant-select-allow-clear .ant-select-selector {
  padding-right: 48px;
}
.selection-message {
  padding: 5px;
  text-align: center;
  background-color: #F2F2F2;
}
.custom-loader {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
  user-select: none;
  background: rgba(255, 255, 255, 0.6);

  .spinner {
    top: 50%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
  }
}
.ant-form-item-label {
  & > label {
    font-size: 14px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.7);
  }
}
.ant-form-vertical {
  .ant-form-item-label {
    padding: 0 0 4px;
  }
}
.ant-modal-close {
  color: #000;
}
.ant-modal-header {
  background: #F9F9F9;
  padding: 16px;

  .ant-modal-title {
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
  }
}
.ant-select-lg {

  &.ant-select:not(.ant-select-customize-input) {
    .ant-select-selector {
      border-radius: 6px;
      background: #F8F8F8;
      border-color: #D9D9D9;
    }
  }  
}

.filter-label {
  font-size: 12px;
  line-height: 16px;
  color: #9A9A9A; 
  margin: 0 0 4px;
}
.authorization {
  display: flex;
  flex-grow: 1;
  align-items: center;
  flex-direction: column;
  // padding: 0 200px;
  text-align: center;

  h3 {
    font-size: 30px;
  }
  h4 {
    font-weight: 100;
  }
  p {
    font-size: 20px;
    color: #0000a0;
  }
  .request-form {
    width: 80%;
  }
}
.selected-filters {
  display: flex;
  align-items: center;
  background: #FAFAFA;
  border: 1px solid #E0E0E0;
  border-radius: 8px;
  padding: 8px 12px;
  margin: 0 0 0px;

  label {
    color: rgba(0, 0, 0, 0.7);
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    margin-right: 8px;
  }

  .filter-item {
    background: rgba(0, 0, 160, 0.05);
    border-radius: 24px;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #0000A0;
    padding: 4px 12px;

    span {
      color: #000;
    }

    &:not(last-child) {
      margin-right: 10px;
    }
  }
}

.trackboard-edit {
  border: 1px solid #0000A0;
}
.trackboard-selected {
  background: rgba(0, 0, 160, 0.06) !important;
}
.performance-section-title {
  display: flex;
  justify-content: space-between;

  .pst-left {
    margin-right: auto;
  }
  .pst-right {
    margin-left: auto;
  }
}
.common-callout {
  background: rgba(0, 0, 160, 0.09);
  border: 1px solid #0000A0;
  border-radius: 2px;
  padding: 10px;
  margin: 0 0 10px;

  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #0000A0;
    margin: 0;
  }
}
.vs-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 28px;
}
.mb-20{
  margin-bottom: 20px;
}

.toggle-switch {
  display: flex;
  align-items: center;

  .ant-switch {
    margin: 0 9px;
    background-color: #0000A0;
    background-image: none;
  }
  span {
    font-size: 14px;
    line-height: 24px;
    color: #8F8F8F;

    &.active {
      color: #0000A0;
    }
  }
}
.user-info-wrap {
  display: flex;
  align-items: flex-start;
  margin: 0 0 20px;

  .user-info {
    margin-left: 8px;

    h4 {
      font-size: 16px;
      line-height: 20px;
      color: #000;
      margin: 0 0 2px;
    }
  }
  .audience {
    height: 24px;
    display: flex;
    align-items: center;
    border-radius: 14px;
    border: 1px solid #3C3C3C;
    background: #fff;
    padding: 2px 8px;
    cursor: pointer;

    .audience-name {
      margin: 0 8px 0 6px;
      font-size: 12px;
      line-height: 16px;
    }
  }
}
.reports-wrap {
  border: 1px solid #E0E0E0;
  border-radius: 6px;
  padding: 16px 26px;

  .reports-head {
    margin: 0 0 20px;
    padding: 0 0 9px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #E0E0E0;

    .rh-left {
      h5 {
        font-size: 16px;
        line-height: 20px;
        margin: 0 0 4px;
      }
      span {
        font-size: 12px;
        line-height: 16px;
        color: #8F8F8F;
      }
    }
    .rh-right {
      svg {
        cursor: pointer;
      }
    }
  }
  .reports-search {
    margin: 0 0 10px;

    .ant-input {
      padding: 4px 11px;
      background: rgba(243, 243, 243, 0.63);
      border: 1px solid rgba(196, 196, 196, 0.6);
      border-radius: 2px;
    }
    .ant-input-search .ant-input:hover, 
    .ant-input-search .ant-input:focus {
      border-color: rgba(196, 196, 196, 0.6);
    }
    .ant-input-search .ant-input:focus + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary) {
      border-left-color: rgba(196, 196, 196, 0.6);
    }
    .ant-btn {
      border-width: 1px;
      background: rgba(243, 243, 243, 0.63);
      border-color: rgba(196, 196, 196, 0.6);
    }
  }
  .reports-body {
    overflow: auto;
    max-height: 450px;
    padding: 10px;
    margin: 0 -10px;
  }
}
.custom-tracker {
  &::-webkit-scrollbar-track {
    border-radius: 4px;
    background-color: #fff;
  }

  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #D0D0D0;
  }
}
.ant-table-thead th.ant-table-column-has-sorters:hover,
.ant-table-thead th.ant-table-column-has-sorters:hover .ant-table-filter-trigger-container,
.ant-table-filter-trigger-container-open, 
.ant-table-filter-trigger-container:hover, 
.ant-table-thead th.ant-table-column-has-sorters:hover .ant-table-filter-trigger-container:hover {
  background: #0000a0;
}
.ant-table-filter-trigger-container-open .ant-table-filter-trigger, 
.ant-table-filter-trigger:hover {
  color: #bfbfbf;
}
.ant-table-filter-trigger.active {
  color: #bfbfbf;
}
.ant-table-column-sorters {
  padding: 4px 10px;
}
.ant-select-tree-list-scrollbar {
  display: block !important;
}
.ant-table-filter-trigger {
  color: #fff;
  margin: 5px;
  border-radius: 6px;

  &.active {
    background: #817ed5;
    color: #fff;
  }
}
.td-border-left {
  border-left: 2px solid rgba(0, 0, 0, 0.03);
}
// .sub-td-border-left {
//   border-left: 1px solid rgba(0, 0, 0, 0.03);
// }
.product-column {
  padding-left: 5px !important;
  padding-right: 5px !important;
}
.ant-dropdown-menu {
  padding: 0px;
  .ant-dropdown-menu-item {
    p {
      margin: 8px 0;
      color: #8F8F8F;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;

      strong {
        color: #000;
      }
    }
  }
}
.f-note {
  .ant-form-item-label > label {
    color: #000;
    font-size: 16px;
    font-weight: 700;
  }
}
.ant-tabs-tab.ant-tabs-tab-active {
  .ant-tabs-tab-btn {
    text-shadow: none;
  }
}