.nr-left {
    flex: 1 1 auto;

    .nr-time-date {
        list-style: none;
        margin: 0;
        padding: 0;
    }
    .nr-time-date {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin: 6px 0 0;

        li {
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            color: #8F8F8F;

            &:not(:first-child) {
                &::before {
                    content: "•";
                    margin: 0 4px;
                }
            }
            
        }        
    }
}
.nr-right {
    display: flex;
    align-items: center;
    margin-left: 8px;
    flex: 0 0 auto;
    
    a {
        margin: 0 4px;
    }
}
.review-modal {
    .ant-modal-content {
        padding: 16px 24px;
        
        .ant-modal-close {
            top: 26px;
            right: 24px;

            .ant-modal-close-x {
                width: 16px;
                height: 16px;
                line-height: 16px;
            }
        }
        

        .ant-modal-header {
            padding: 6px 0;
            background: none;

            .ant-modal-title {
                font-weight: 700;
                font-size: 14px;
                line-height: 24px;
                color: #000;
            }
            
        }
        .ant-modal-body {
            padding: 16px 0;

            .comment-text {
                h4 {
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 24px;
                    margin: 0 0 6px;
                    color: #000000;
                }
                p {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 24px;
                    margin: 0 0 6px;
                    color: #000000;
                }
                span {
                    font-weight: 400;
                    font-size: 10px;
                    line-height: 24px;
                }
            }
        }
    }
}