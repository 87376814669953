.trackboard-top {
    margin: 0 0 32px;
}
.ps-top {
    background: #FFFFFF;
    padding: 16px 14px;
    border-radius: 12px;
}
.performance-filter {
    label {
        font-size: 12px;
        line-height: 16px;
        color: #9A9A9A; 
        margin: 0 0 4px;
    }
}
.card-style{
    h4{
        font-size: 12px !important;
        color: #575555 !important;
        font-weight: normal !important;
    }
    .data-value{
        font-size: 25px !important;
        line-height: 40px !important;

    }
}
.chart-btn{
    border-color: #dadaff;
    margin-right: 10px;
    width: 80px;
    text-align: center;
    cursor: pointer;
}
.data-card {
    background: #fff;
    border-radius: 12px;
    padding: 40px 10px;
    text-align: center;
    box-shadow: 0px 0px 42.6642px 0px rgba(0, 0, 0, 0.06);

    .data-head {
        margin: 0 0 16px;
        padding: 0 16px;
        position: relative;

        h4 {
            color: #000;
            font-size: 18px;
            line-height: 24px;
            margin: 0;
        }
        .d-check {
            top: 0;
            left: 0;
            position: absolute;
        }
        .d-more {
            top: 0;
            right: 0;
            position: absolute;
        }
    }
    .data-value {
        font-size: 47px;
        line-height: 74px;
        color: #0000A0;
    }
    .comparison-wrap {
        display: flex;
        justify-content: center;
        margin-top: 24px;

        .c-box {
            border: 1.5px solid #DCDCDC;
            border-radius: 14px;
            padding: 14px;
            min-width: 160px;
            margin: 0 3px;

            .c-box-title {
                color: #393939;
                font-size: 14px;
                line-height: 18px;
                margin: 0 0 6px;
            }
            .c-box-value {
                font-weight: 600;
                font-size: 14px;
                line-height: 20x;

                &.green {
                    color: #00C853;
                }
                &.red {
                    color: #EC3225;
                }
            }
        }
    }
}

.section-title {
    padding: 0 16px;
    position: relative;
    margin: 0 0 24px;

    h4 {
        color: #000;
        font-size: 18px;
        line-height: 24px;
        margin: 0;
        text-align: center;
    }
    .section-title-callout {
      font-size: 14px;
      color: #0000008a;
    }
    .callout-above {
      margin-bottom: 20px;
    }
    p {
      color: #a7a3a3;
      font-size: 12px;
      text-align: center;
    }
    .d-more {
        top: 0;
        right: 0;
        position: absolute;
    }
}
.chart-wrap {
    padding: 20px;
    background: #FFFFFF;
    border-radius: 12px;
    height: 100%;
}
.table-chart {
    padding: 20px 10px 10px;
    background: #FFFFFF;
    border-radius: 12px;
}
.year-filter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 24px;

    .ant-radio-group {
        margin-left: auto;
    }

    
}
.sku-wrap {
    padding: 16px;
    border-radius: 12px;
    background: #FFFFFF;

    .sku-chart-left {
        // border-right: 1px solid #8F8F8F;
        background: #fff;
        padding: 10px;
        border-radius: 6px;
        height: 100%;

        .circle-chart {
            padding: 16px 24px 24px;
            margin-right: 10px;
            border: 1px solid #DCDCDC;
            border-radius: 4px;
        }
    }
    .sku-table-wrap {
        background: #fff;
        padding: 10px;
        border-radius: 6px;
        height: 100%;
    }
}
.nutro-skus {
    padding: 16px;
    background: #FFFFFF;
    box-shadow: 0px 0px 42.6642px 0px rgba(0, 0, 0, 0.06);
    border-radius: 12px;

    .nutro-skus-filter {
        display: flex;
        align-items: center;
        padding: 10px;
        margin-bottom: 10px;
        background: rgba(234, 234, 234, 0.6);

        > div {
            margin-right: 24px;

            .view-label {
                font-size: 12px;
                line-height: 16px;
                color: #393939;
                display: inline-block;
                margin-right: 8px;
            }
        }
        .ant-radio-group-small {
            .ant-radio-button-wrapper {
                margin-right: 4px;
                padding: 2px 16px;
                border-radius: 6px;
                border-left-width: 1px;
                font-size: 12px;
                line-height: 14px;
                height: 20px;

                &:not(:first-child)::before {
                    content: none;
                }
            }
        }
        
        .nutro-skus-filter-category {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          margin-right: 0;

          .ant-input {
            font-size: 10px;
            height: 21px;
            width: 64px;
            border-radius: 3px;
            background-color: #fff;
          }

          .nutro-skus-filter-children {
            display: flex;
            align-items: center;
            .left-child {
              margin-right: 10px;
            }
            .right-child {
              margin-left: 10px;
            }
          }
        }

        .nutro-sku-filter-description {
          white-space: nowrap;
          font-size: 12px;
          line-height: 16px;
          text-align: right;
          
          p {
            margin-bottom: 0;
            .total-count {
              color: #000;
            }
            span {
              color: #0000A0;
              font-weight: 700;
            }

          }
        }
    }
}
.scatter-info {
    margin: 10px 10px 10px 0;
    background: #F2F2F2;
    padding: 0 10px;
    text-align: center;

    ul {
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        > li {
            margin: 4px 6px;

            &:first-child {
                flex: 1 0 100%;
                text-align: center;
            }

            label {
                font-weight: 600;                
            }
            span {
                margin-left: 5px;
            }
        }
    }
}