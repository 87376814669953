.header-filter{
  margin-bottom: 16px;
}

.trackboard-top-vs {
  margin: 0 0 32px;
  display: flex;
}
.filters-ps {
  display: flex;

  .category-first{
    width: 130px;
    
  }
  .category-second{
    width: 140px;
    
  }
  .category-third{
    width: 85px;
    
  }
  .category-fourth{
    width: 80px;
    
  }
  .category-fifth{
    width: 68px;
    
  }
}
.category-button{
  margin-top: 27%;
}
.report-header{
  margin-top: 3%;
 
}
.divider-margin{
  border-top-color: #dcdcdc;
  margin-top: 24px;
}
.competition-header{
width: 100px;
}
.competition-header-second{
  width:220px;
}
.vs-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 28px;
}
.analysis-data-wrap {
  background: #ffffff;
  border: 0.5px solid #c4c4c4;
  border-radius: 3px;
  padding: 24px 38px;
  text-align: center;
  position: relative;
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width:1365px) {
    padding: 24px 18px;
  }

  &.active {
    border-color: #8f8f8f;

    &::after {
      content: "";
      border-left: 11px solid transparent;
      border-right: 11px solid transparent;
      border-bottom: 20px solid #fff;
      left: 50%;
      bottom: -27px;
      position: absolute;
      transform: translateX(-50%);
    }
  }

  .kpi-status {
    padding: 8px;
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;

    p {
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      margin: 0;
      color: #fff;
    }
  }

  .analysis-data {
    .analysis-data-title {
      font-size: 12px;
      line-height: 16px;
      font-weight: 700;
      margin: 0 0 2px;
    }
    .analysis-data-value {
      font-size: 14px;
      line-height: 19px;
      font-weight: 600;
      margin: 0;
    }
  }
}
.a-card-title {
  color: #000;
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  text-align: center;
  margin: 0 auto 8px;
  max-width: 138px;
}
.a-card-title-container {
  margin: 0 0 8px;
}
.competition-analysis {
  background: #f9f9f9;
  border: 0.5px solid #f0f0f0;
  box-shadow: 0px 0px 12px 8px rgba(0, 0, 0, 0.06);
  border-radius: 3px;
  padding: 16px;
  margin-bottom: 24px;
  position: relative;

  .d-more {
    top: 10px;
    right: 6px;
    position: absolute;
    z-index: 9;
  }

  .l-card {
    min-width: 126px;
    // max-width: 180px;
    width: 100%;
  }

  .competition-analysis-footer {
    margin-top: 20px;
    padding: 12px 0;
    border-top: 1px solid #acacac;
    text-align: right;

    button {
      margin-left: 8px;
     
    }
  }

  .row-card{
    flex-wrap: nowrap;
    
    
  }
  .d-check{
    margin-bottom: 8px;
  }
  .filter-dataa{
    width: 100%;
  }
  .show-search{
    width: 100%;
    max-width: 138;
  }
  .divider-report{
    margin: 48px 0;
  }
  .brand-data {
    background: #ffffff;
    border: 0.5px solid #c4c4c4;
    border-radius: 3px;
    padding: 10px 5px;
    text-align: center;
    width: 125px;
    height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .brand-image {
      max-width: 100%;
      width: 72px;
      position: relative;
      margin: 0 auto 10px;

      &::after {
        content: "";
        display: block;
        padding-bottom: 100%;
      }

      img {
        top: 50%;
        left: 50%;
        position: absolute;
        transform: translate(-50%, -50%);
        width: 100%;
        object-fit: cover;
      }
    }

    p {
      font-size: 12px;
      line-height: 16px;
      font-weight: 600;
      margin: 0;
      color: #000;
    }
  }
}
.competition-card{
  max-width: 200;
  flex: 1 0 auto;
}

.customer-channel-data{
  margin-top: 26px;
  
}