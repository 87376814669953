.waterfall-container{
    border-radius: 12px;
    background: #F9F9F9;
    padding: 10px 10px;
    text-align: center;
    box-shadow: 0px 0px 42.6px 0px rgba(0, 0, 0, 0.06);
    
    .section-title{
        display: flex;
        justify-content: space-between;
        .filter {
            display: flex;
            width: 15%;
            text-align: left;
            label {
                font-size: 12px;
                line-height: 16px;
                color: #9A9A9A; 
                margin: 0 0 4px;
            }
            .ant-radio-button-wrapper {
                line-height: 30px !important;
                color: rgba(60, 60, 60, 0.85) !important; 
                margin: 0 !important;
            }
        }
        .filter-vs {
            display: flex;
            width: 15%;
            text-align: left;
            justify-content: space-around;
            align-items: end;
            label {
                font-size: 12px;
                line-height: 16px;
                color: #9A9A9A; 
                margin: 0 0 4px;
            }
            .ant-radio-button-wrapper {
                line-height: 30px !important;
                color: rgba(60, 60, 60, 0.85) !important; 
                margin: 0 !important;
            }
        }
        h4 {
            color: #000;
            font-size: 18px;
            line-height: 24px;
            margin: 0;
            text-align: center;
        }
        .dropdown-container{
            width: 15%;
        }
    }
    width: 100%;
    // justify-content: center;
    // display: flex;
    .waterfall-chart{
        margin: auto;
        width: 80%;
        justify-content: center;
        align-items: center;
        height: 250px;
        .no-data{
            display: flex;
            font-size: 20px;
            height: 200px;
            justify-content: center;
            align-items: center;
        }
    }
}