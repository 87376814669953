.be-available-card {
  padding: 16px 20px 20px 20px;
  background: #ffffff;
  box-shadow: 0px 0px 20px 14px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
  .d-check {
    top: 0;
    bottom: 0;
    position: absolute;
  }

  .be-available-card-title {
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: #000000;
  }

  &.left-70 {
    flex: 0 0 calc(70% - 14px);
  }
  &.right-30 {
    flex: 0 0 30%;
  }
  &.left-40 {
    flex: 0 0 calc(40% - 14px);
  }
  &.right-60 {
    flex: 0 0 60%;
  }
}

.be-available-card-title {
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: #000000;
}

.d-check-consumer {
  position: absolute;
}

.top-chart-margin {
  margin-top: 24px;
}
.be-available-card-bg {
  background: #ffffff;
  padding: 16px 14px;
  border-radius: 12px;
}
.iv-five-column {
  display: flex;
  justify-content: center;
  margin: 0 0 16px;

  .item {
    width: calc((100% + 240px) / 5);
    background: #ffffff;
    box-shadow: 0px 0px 20px 14px rgba(0, 0, 0, 0.06);
    border-radius: 12px;
    padding: 16px 56px 16px 24px;
    text-align: center;
    margin-right: 16px;

    > h5 {
      font-weight: 700;
      font-size: 16px;
      line-height: 21px;
      color: #000000;
      margin: 0 0 16px;
    }

    .item-value-wrap {
      display: flex;
      justify-content: space-between;

      .item-value {
        h5 {
          font-weight: 700;
          font-size: 14px;
          line-height: 18px;
          color: rgba(0, 0, 0, 0.75);
          margin: 0 0 8px;
        }
        p {
          font-weight: 700;
          font-size: 24px;
          line-height: 44px;
          color: #0000a0;
          position: relative;
          margin: 0;

          span {
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            color: #ec3225;
            position: absolute;
            left: calc(100% + 5px);
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }
    }
  }
}
.be-available-card-wrap {
  display: flex;
  justify-content: space-between;
}
.revenue-wrap {
  display: flex;
  align-items: flex-start;

  .revenue {
    padding: 8px 30px;
    background: #ffffff;
    box-shadow: 0px 0px 21px 14px rgba(0, 0, 0, 0.06);
    border-radius: 12px;
    margin-right: 24px;
    text-align: center;
    margin: auto;

    > h5 {
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      color: #000;
      margin: 0 0 12px;
    }

    .revenue-item {
      padding-left: 12px;
      padding-right: 12px;

      + .revenue-item {
        border-top: 1px solid #c4c4c4;
        margin-top: 12px;
        padding-top: 12px;
      }
      h5 {
        font-weight: 700;
        font-size: 14px;
        line-height: 18px;
        color: rgba(0, 0, 0, 0.75);
        margin: 0;
      }
      p {
        font-weight: 700;
        font-size: 32px;
        line-height: 44px;
        color: #0000a0;
        margin: 0;
        position: relative;

        span {
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          color: #ec3225;
          position: absolute;
          left: calc(100% + 4px);
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
  }
  .bar-chart {
    flex: 1 0 auto;
  }
}
.review-title {
  color: #0000a0 !important;
  margin: 0 0 20px;
}
.custom-radio {
  .ant-radio-button-wrapper {
    height: auto;
    text-align: center;
    border: none !important;
    background: #eeeeee;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #000000;
    padding: 12px;
    border-radius: 4px !important;
    margin-right: 16px;

    &::before {
      display: none !important;
    }

    + .ant-radio-button-wrapper {
      margin: 16px 16px 0 0;
    }
  }
}

.reviews-wrap {
  display: flex;
  justify-content: space-between;

  .review-section-title {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: #0000a0;
    text-align: center;
    margin: 0 0 12px;
  }

  .rating-wrap {
    flex: 0 0 69.83%;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    display: flex;

    .rating {
      flex: 0 0 64.86%;
      padding: 0 20px 25px 20px;

      .rating-head {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 16px 0;
        border-bottom: 1px solid #e0e0e0;

        h5 {
          font-weight: 700;
          font-size: 16px;
          line-height: 22px;
          color: #0000a0;
          margin: 0;
          padding: 0 36px;
        }

        .disabled {
          color: #d3d3d3;
          cursor: default;
        }
      }
      .rating-head-c {
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: center;
        padding: 16px 0;
        border-bottom: 1px solid #e0e0e0;
      }
      // .rating-stats {
      //   padding: 20px 0;

      //   ul {
      //     margin: 0;
      //     padding: 0;
      //     list-style: none;
      //     display: flex;
      //     align-items: center;

      //     li {
      //       display: flex;
      //       align-items: center;

      //       label {
      //         font-weight: 400;
      //         font-size: 14px;
      //         line-height: 18px;
      //         color: #424242;
      //         margin-right: 8px;
      //       }
      //       span {
      //         font-weight: 700;
      //         font-size: 14px;
      //         line-height: 18px;
      //         color: #424242;
      //       }
      //       + li {
      //         margin-left: 50px;
      //       }
      //     }
      //   }
      // }
      .rating-stats {
        display: flex;
        justify-content: space-between;
        margin: 5px 0;
        label {
          margin-right: 5px;
        }
        div {
          display: flex;
          span {
            // margin-left: 5px;
            font-weight: 700;
            .ant-rate {
              color: #fab518;
            }
            .ant-rate-star {
              margin-right: 4px;
            }
          }
        }
      }
      .rating-chart-wrap {
        background: #ffffff;
        box-shadow: 0px 0px 21px 14px rgba(0, 0, 0, 0.06);
        border-radius: 10px;
        padding: 16px 22px;

        .rating-chart-head {
          font-weight: 700;
          font-size: 14.2214px;
          line-height: 19px;
          text-align: center;
          color: #000000;
          margin: 0 0 26px;
        }
      }
    }
    .analysis {
      padding: 16px 22px;
      border-left: 1px solid #e0e0e0;
      flex: 1 1 auto;

      .sentiment-box {
        padding: 22px 32px 26px 32px;
        border-radius: 10px;
        text-align: center;
        margin: 0 0 20px;

        img {
          width: 33px;
          margin: 0 0 8px;
        }
        h5 {
          font-weight: 700;
          font-size: 14px;
          line-height: 18px;
          margin: 0 0 8px;
        }
        p {
          font-weight: 400;
          font-size: 14px;
          line-height: 18px;
          color: #8e8e8e;
          margin: 0;
        }

        &.positive {
          background: rgba(0, 200, 83, 0.09);
          h5 {
            color: #00c853;
          }
        }
        &.negative {
          background: rgba(255, 239, 231);
          h5 {
            color: #f65f0a;
          }
        }
        &.neutral {
          background: rgba(254, 242, 214);
          h5 {
            color: #fab518;
          }
        }
      }
    }
    .analysis-list {
      padding: 16px;
      border-left: 1px solid #e0e0e0;
      // flex: 1 1 auto;
      display: flex;
      flex-direction: column;
      // align-items: space-evenly;

      .sentiment-box {
        display: flex;
        align-items: center;
        padding: 5px 10px;
        border-radius: 8px;
        text-align: center;
        margin: 0 0 15px;

        img {
          width: 15px;
          margin-right: 5px;
        }
        h5 {
          font-weight: 700;
          font-size: 12px;
          line-height: 18px;
          // margin: 0 0 8px;
        }
        p {
          font-weight: 400;
          font-size: 14px;
          line-height: 14px;
          color: #8e8e8e;
          margin: 0;
        }

        &.positive {
          background: rgba(0, 200, 83, 0.09);
          span {
            color: #00c853;
          }
        }
        &.negative {
          background: rgba(255, 239, 231);
          span {
            color: #f65f0a;
          }
        }
        &.neutral {
          background: rgba(254, 242, 214);
          span {
            color: #fab518;
          }
        }
      }
    }
  }
  .traffic {
    flex: 0 0 28.93%;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    padding: 16px;
    .review-section {
      display: flex;
      align-items: baseline;
      justify-content: space-between;
    }
    .filter-box {
      display: flex;
      justify-content: space-around;
      padding: 6px;
      border-radius: 10px;
      text-align: center;
      align-items: center;
      margin: 0 0 20px;
      background: #eeeeee;
      .filter-inner {
        display: flex;
        flex-direction: column;
      }
    }
    .review-list {
      overflow: auto;
      max-height: 488px;
    }
    .review-box {
      height: auto;
      padding: 10px 14px 0px 14px;
      border-radius: 10px;
      text-align: center;
      margin: 0 0 20px;
      background: #eeeeee;
    }
  }
}

.top-keywords {
  margin: 0 0 20px;

  h5 {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #424242;
    margin: 0 0 12px;
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      display: inline-block;
      border-radius: 24px;
      padding: 4px 12px;
      background: rgba(0, 0, 160, 0.05);
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #0000a0;
      margin: 0 6px 12px 0;
    }
  }
}

.borderless-table {
  .ant-table-thead > tr > th {
    color: #8a8a8a;
    border: none;
    background: none;
    border: none;

    &::before {
      display: none;
    }
  }
  .ant-table-tbody > tr > td {
    border: none;
    padding: 24px 16px;

    &.data {
      font-weight: 700;
      font-size: 22px;
      line-height: 26px;
      color: #0000a0;
    }
    &.type {
      font-weight: 400;
      font-size: 18px;
      line-height: 22px;
      color: #000000;
    }
  }
}

.btn-ennabled {
  color: #000;
}
.btn-dissabled {
  opacity: 0.5;
}
.be-available-list {
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 0 35px;
}

.ba-kpi-items {
  display: flex;
  justify-content: space-between;

  .ba-kpi-item {
    width: 32.7%;
    background: #ffffff;
    box-shadow: 0px 0px 42.6642px 28.4428px rgba(0, 0, 0, 0.06);
    border-radius: 12px;
    padding: 16px;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
    
    .ba-kpi-item-head {
      position: relative;

      > h5 {
        color: #000;
        font-size: 16px;
        margin-top: 8px;
      }
  
      .d-more {
        top: 0;
        right: 0;
        position: absolute;
      }
    }

    

    > p {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 40px;
      font-weight: 700;
      font-family: "mars-centra-bold", Arial, sans-serif;
      color: #0000a0;
      margin-bottom: 8px;

      > span {
        font-size: 18px;
        font-weight: 400;
        color: #00c853;
        margin-left: 8px;
      }
      .positive {
        color: #00c853;
      }
      .negative {
        color: #ec3225;
      }
    }
  }
}

.collapse-expand {
  // .ant-collapse {
  //   border: none;
  // }
  // .ant-collapse-content {
  //   border: none;
  // }
  // .ant-collapse > .ant-collapse-item {
  //   border: none;
  // }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header div {
    margin-left: 16px;
  }

  .ant-collapse
    > .ant-collapse-item
    > .ant-collapse-header
    .ant-collapse-arrow {
    font-size: 16px;
    vertical-align: 0;
    position: absolute;

    // &::after {
    //   content: '';
    //   height: 1px;
    //   width: 150vh;
    //   position: absolute;
    //   background: #cccccc;
    //   left: 24px;
    //   bottom: 6px;
    // }
  }

  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    position: inherit;
    padding: 12px 16px;
    color: #000000;
    font-size: 22px;
    font-family: "mars-centra-bold", Arial, sans-serif;
    font-weight: 700;
    margin: 16px 0;
  }
}

.sub-card-container {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;

  .sub-card-item {
    width: 49.5%;
  }
}

.next-skus {
  margin-top: 10px;

  .ant-table-thead > tr > th {
    white-space: nowrap;
  }
  .ant-table-body .ant-table-cell {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .ant-table-cell {
    word-break: unset;
    padding-left: 5px;
    padding-right: 5px;
  }

  .ant-table-column-has-sorters {
    padding-top: 0;
    padding-bottom: 0;
  }
  .ant-table-column-sorter {
    margin-right: 5px;
  }
}

.chart-filter {
  text-align: right;
  margin-bottom: 10px;

  .chart-filter-title {
    margin-right: 10px;
    color: #a7a3a3;
  }
}
.beAvailable-card {
  background-color: white;
  margin-bottom: 16px;

  &::before {
    content: "";
    height: fit-content;
    background: rgb(128, 235, 220);
    width: 9px;
    min-height: 50px;
    border-radius: 5px;
    left: -10px;
    position: relative;
  }
  div {
    margin: 10px 0 0 0;
    font-size: 13px;
    span img {
      margin-top: -3px;
      margin-right: 3px;
    }
    b {
      font-size: 18px;
      font-weight: bolder;
      color: black;
    }
  }
}
.ant-table-kpi {
  display: flex;
  justify-content: center;
  .ant-table-abs {
    white-space: nowrap;
    margin-left: 5px;
  }
  .ant-table-variance {
    white-space: nowrap;
    margin-left: 10px;
    margin-right: 5px;
  }
}
.available-main{
  display: flex;
  align-items: center;

  .availableVisible-icon{
    width: 22px;
    path{
      fill: #0000a0;
    }
  }
}
.ba-info{
  margin-right: 5px;
}