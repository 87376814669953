.ecomdash-content {
  background-color: #fff;
} 

.trackboard-head {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #393939;
    margin: 0 0 8px;
  }


  .disclaimer{
    
    padding: 10px;
    display: flex;
    background: #f9f9f9;
    border: aliceblue;
    border-radius: 10px;
    margin: 3px;
    width: 100%;
    color: darkgreen;
  }
  
  .ecom-deep-dive{
    text-align: left;

        
  .eCom-outer-card {
    background: #F2F2F2;
    border-radius: 10px;
    width: 100%;
    height: 100%;
    
    .ecom-inner-card{
      padding-inline: 8px;
      position: relative;
      &::before{
        transform: rotate(90deg);
        display: block;
        width: 53px;
        height: 51px;
        position: absolute;
        content: "";
        background: regb(0, 0, 0, 0.5);
        top: 0;
        left: 0;
        border-bottom-left-radius: 10px;
        box-shadow: 0 0 0 25px #fff;
        clip: rect(0, 30px, 51px, 0);
      }
      &::after{
        transform: rotate(180deg);
        display: block;
        width: 53px;
        height: 51px;
        position: absolute;
        content: "";
        background: regb(0, 0, 0, 0.5);
        top: 0;
        right: 0;
        border-bottom-left-radius: 10px;
        box-shadow: 0 0 0 25px #fff;
        clip: rect(0, 30px, 51px, 0);
      }
    }
    .eCom-heading{
      display: flex;

      h3{
        
          color: orange;
          font-weight: lighter;
          margin-bottom: 0px;
          word-spacing: 2px;
          margin-left: 8px;
          font-size: 16px;
          
      }
      
  .ecom-heading-two{
    color: #48c1e8;
  }
  .ecom-heading-third{
    color: #e71da1;
  }
  .ecom-heading-forth{
    color: #00c853;
  }
  }


    .inner-card {
      background: #fff;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      max-height: 18vh;
      height: 200px;
    }
    .card-header {
      justify-content: center;
      font-size: 1.5vh;
      line-height: 1;
      font-weight: bold;
      color: #000000;
      // padding-top: 8px;
    }
    
  
    .card-data {
      justify-content: center;
      font-size: 1.5vh;
      line-height: 1;
      font-weight: bold;
      color: #0000a0;
    }
    .card{
      display: flex;
      justify-content: space-evenly;
    }
    .card-kpi{
        // margin-bottom:8px;
        padding: 5px;
        align-items: center;
        font-size:11px;
        margin-right: 10px;
        display:flex;
        justify-content: space-between;
        // border:2px solid lightgray;
        border-radius: 10px;
        line-height: 1 !important;
        width:35%;
    }
  
    .card-kpi-mars{
      // margin-bottom:8px;
      padding: 5px;
        align-items: center;
        font-size:11px;
        margin-right: 10px;
        display:flex;
        justify-content: space-between;
        // border:2px solid lightgray;
        border-radius: 10px;
        line-height: 1 !important;
        width:35%;
  }
  
    .card-kpi-black {
      justify-content: center;
      color: #000000;
    }
  
    .card-kpi-red {
      justify-content: center;
      font-weight: bold;
      color: red;
    }
  
    .card-kpi-green {
      justify-content: center;
      font-weight: bold;
      color: green;
    }
  
    .button-div{
      display: flex;
      justify-content: center;
      margin-top: 10px;
      margin-bottom: 10px;
    }
    .button-div2{
      display: flex;
    justify-content: center;
    width: 100%;
    transform: translateY(-9px);
    .ant-btn {
      justify-content: center;
      font-weight: 700;
      font-size: 14px;
      line-height: 18px;
      width: 100%;
      border-radius: 6px;
      border-color: #0000a0;
      border-width: 2px;
  }
    }
    .button{
      display: flex;
      justify-content: flex;
      border-radius: 5px; 
    }
  }
  }
  
  .trackboard-info {
    text-align: center;
    width: 100%;
    background: #F2F2F2;
    border-radius: 4px;
    padding: 4px 10px;
    font-size: 2.4vh;
    color: #000;
    position: relative;
  
    p {
      margin: 0;
    }
    a {
      top: 50%;
      right: 10px;
      position: absolute;
      transform: translateY(-50%);
    }
  }
  .ecom-info{
    width: 100%;
    padding: 10px;
    background: #fff;
    margin-top: 11px;
    border-radius: 12px;
    margin-bottom: 16px;
    h3{
        color: black;
        font-weight: lighter;
    }
    hr{
        color: lightgray;
    }
    p{
        margin-bottom: 3px;
    }
  }
  .pt-8{
    padding-top: 8px;
  }
  
  .section-second {
    margin-top: 5px;
    margin-left: 0px;
    margin-right: 0px;
    
  }

.imgsrc {
  margin-right: 3px;
}

.vertical {
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 2px;
}

.vertical-column {
  display: flex;
  flex-flow: nowrap;
}
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }
  .site-layout-background{
    background: #f5f5f5;
  }