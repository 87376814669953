.trackboard-content {
  background: #fff;
  height: 41vh;
}
.trackboard-head {
  font-weight: 500 !important;
  font-size: 2.2vh !important;
  line-height: 24px;
  color: #393939 !important;
  margin: 0 0 8px; 
}

.trackboard-row {
  height: 90%; 
}
.trackboard-column {
  margin-top: 10px;
  
}
.trackboard-column-content {
  padding-left: 0px;
  padding-right: 5px;
}
.trackboard-column-content-right {
  padding-left: 0px;
  padding-right: 0px;
  
}
.deep-dive{

      text-align: -webkit-center;
.outer-card {
  padding: 8px;
  border-radius: 10px;
  width: 80%;
  
  .inner-card {
    background: #fff;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    max-height: 18vh;
    height: 200px;
  }
  .card-header {
    justify-content: center;
    font-size: 1.5vh;
    line-height: 1;
    font-weight: bold;
    color: #000000;
    // padding-top: 8px;
    
  }
  

  .card-data {
    flex-direction: column;
    justify-content: center;
    font-size: 1.5vh;
    line-height: 1;
    font-weight: bold;
    color: #0000a0;
  }
  .card{
    display: flex;
    justify-content: space-evenly;
    
  
  }
  .card-kpi{
      // margin-bottom:8px;
      padding: 5px;
      align-items: center;
      font-size:11px;
      margin-right: 10px;
      display:flex;
      justify-content: space-between;
      // border:2px solid lightgray;
      border-radius: 10px;
      line-height: 1 !important;
      width:35%;
  }

  .card-kpi-mars{
    // margin-bottom:8px;
    padding: 5px;
      align-items: center;
      font-size:1.5vh;
      margin-right: 10px;
      display:flex;
      justify-content: space-evenly;
      // border:2px solid lightgray;
      border-radius: 10px;
      line-height: 1 !important;
      width:35%;
}

  .card-kpi-black {
    justify-content: center;
    color: #000000;
  }

  .card-kpi-red {
    justify-content: center;
    font-weight: bold;
    color: red;
  }

  .card-kpi-green {
    justify-content: center;
    font-weight: bold;
    color: green;
  }

  
  .button-div{
    
    bottom: 2%; 
    width: 100%;
    .ant-btn {
      justify-content: center;
    align-items: center;
      font-weight: 700;
      font-size: 14px;
      line-height: 18px;
      width: 100%;
      height: 100%;
      border-radius: 6px;
      border-color: #0000a0;
      border-width: 2px;
      
  }
  }
  .button{
    height: 15%;
    display: flex;
    justify-content: flex;
    border-radius: 5px; 
    
  }
}
}

.trackboard-info {
  text-align: center;
  width: 100%;
  background: #F2F2F2;
  border-radius: 4px;
  padding: 4px 10px;
  font-size: 2.4vh;
  color: #000;
  position: relative;
  margin-bottom: 0;

  p {
    margin: 0;
    font-weight: 700;
    
  }
  a {
    top: 50%;
    right: 10px;
    position: absolute;
    transform: translateY(-50%);
  }
}
