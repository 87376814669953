.header-row{
    display: flex;
    width: 100%;
    padding-bottom: 10px;
    border-bottom: 1px solid lightgray;
    justify-content: space-between;
    padding: 20px 30px;
    .header-text{
        color: black;
        font-weight: 1000;
        font-size: larger;
    }
}

.data-tab{
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    margin-top: 16px;
    
    .data-header{
        padding: 15px;
        padding-bottom: 10px;
        color: black;
        font-weight: 1000;
        display: flex;
        justify-content: space-between;
    }
    .data-details{
        padding-left: 15px;
        padding-bottom: 6px;

        &:last-child {
            padding-bottom: 15px;
        }
    }
}

.d-left {
    border: 1px solid #EBEBEB;
    border-radius: 6px;
    padding: 16px;

    h5 {
        font-size: 12px;
        line-height: 24px;
        color: #000000;
    }
    .ant-form-item-label {
        padding: 0 !important;

        label {
            font-size: 12px;
            line-height: 24px !important;
            color: #000000;
            opacity: 0.7;
        }
    }
    .ant-form-item {
        margin-bottom: 10px !important;
    }
    // .ant-select-multiple .ant-select-selector {
    //     height: 28px;
    // }
}
.ant-btn-text {
    border: none !important;
    background: none;
}
.ant-collapse {
    > .ant-collapse-item {
        > .ant-collapse-header {
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 18px !important;
            background: #F9F9F9;
            padding: 8px !important;
            display: flex;

            div {
                order: 2;
                margin-left: auto;
            }
        }
        .ant-collapse-content {
            .ant-collapse-content-box {
                padding-left: 0;
                padding-right: 0;
            }
        }
    }
}