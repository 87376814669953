.donut-container{
    background-color: white;
    border-radius: 12px;
    padding: 16px 32px;
    text-align: center;
    box-shadow: 0px 0px 42.6642px 0px rgba(0, 0, 0, 0.06);
    .data-head {
        padding: 0 0 16px;
        position: relative;

        h4 {
            color: #000;
            font-size: 16px;
            line-height: 20px;
            margin: 0;
        }
        .d-check {
            top: 0;
            left: 0;
            position: absolute;
        }
        .d-more {
            top: 0;
            right: 0;
            position: absolute;
        }
    }
    .bullet-chat-wrap {
        position: relative;
        margin-bottom: 32px;
    
        .ytd_toggle {
            position: absolute;
            top: 0;
            left: 0;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 14px;
            line-height: 20px;

            .ant-switch{
                display: block;
                background-image: none;
                background-color: #0000a0 !important;
            }
            .switch {
                margin: 0 10px;
            }
            .selected{
                color: #0000a0;
                font-weight: bold;
                background: none;
            }
            .unselected{
                color: #8F8F8F;
            }
        }
    }
    .kpi-container{
        display: flex;
        justify-content: center;

        .kpi-box{
            border: solid 1.5px #dcdcdc;
            border-radius: 14px;
            width: calc((100% - 20px)/3);
            padding: 10px 2px;
            margin: 0 5px;
            text-align: center;
            &:first-child {
                margin-left: 0;
            }
            &:last-child {
                margin-right: 0;
            }
            .kpi-value{
                font-weight: bold;
                color: #393939;
            }
        }
    }
    // height: 200px;
}