.beVisible-card{
  background-color: white;
  margin-bottom: 16px;

  &::before{
      content: "";
      height: fit-content;
      background: #e71da1;
      width: 9px;
      min-height: 50px;
      border-radius: 5px;
      left: -10px;
      position: relative;
      }
  div{
      margin: 10px 0 0 0;
      font-size: 13px;
      span img{
          margin-top: -3px;
          margin-right: 3px;
      }
      b{
          font-size: 18px;
          font-weight: bolder;
          color: black;
      }
  } 
}
.kpi-items {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 20px;

  .kpi-item {
    width: 19%;
    background: #ffffff;
    box-shadow: 0px 0px 20px 14px rgba(0, 0, 0, 0.03);
    border-radius: 12px;
    padding: 16px;
    text-align: center;

    .kpi-item-head {
      position: relative;

      > h5 {
        color: #000;
        font-size: 16px;
        margin-top: 8px;
      }
  
      .d-more {
        top: 0;
        right: 0;
        position: absolute;
      }
    }

    > p {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 28px;
      font-weight: 700;
      font-family: "mars-centra-bold", Arial, sans-serif;
      color: #0000a0;
      margin-bottom: 8px;

      > span {
        font-size: 18px;
        font-weight: 400;
        color: #00c853;
        margin-left: 8px;
      }
      .positive {
        color: #00c853;
      }
      .negative {
        color: #ec3225;
      }
    }
  }

}
.border-line{
  border-left: 1px;
  border: 1px solid lightgray;
}
.bv-Filters-apply{
  // border: 0px solid #E0E0E0;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  padding: 8px 12px;
  margin: 0 0 0px;
  background: #FAFAFA;
  
.Report-selected-filters {
  display: flex;
  align-items: center;
  background: #FAFAFA;
  border-bottom: 0.5px solid #E0E0E0;
  padding-bottom: 7px;
  margin-bottom: 7px;

  label {
    color: rgba(0, 0, 0, 0.7);
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    margin-right: 8px;
  }

  .filter-item {
    background: rgba(0, 0, 160, 0.05);
    border-radius: 24px;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #0000A0;
    padding: 4px 12px;

    span {
      color: #000;
    }

    &:not(last-child) {
      margin-right: 10px;
    }
  }
}
.Keyword-selected-filters {
  display: flex;
  align-items: center;
  background: #FAFAFA;

  label {
      color: rgba(0, 0, 0, 0.7);
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      margin-right: 8px;
    }
  
    .filter-item {
      background: rgba(0, 0, 160, 0.05);
      border-radius: 24px;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #0000A0;
      padding: 4px 12px;
  
      span {
        color: #000;
      }
  
      &:not(last-child) {
        margin-right: 10px;
      }
    }
}
}
.traffic-chart{
justify-content: center;
margin-bottom: 40px;
margin-top: 40px;
align-items: baseline;

p{
  margin-right: 10px;

  a{
    font-weight: bold;
    text-decoration-line: underline;
  }
}
}
.ant-table-kpi {
display: flex;
justify-content: center;
.ant-table-abs {
  white-space: nowrap;
  margin-left: 5px;
}
.ant-table-variance {
  white-space: nowrap;
  margin-left: 10px;
  margin-right: 5px;
}
}
.ant-table-columm-expand {
display: flex; 
justify-content: center;
align-items: center;
}
.icon-main{
display: flex;
align-items: center;
}
.visible-icon{
width: 22px;
path{
  fill: #0000a0;
}
}
.be-info{
margin-right: 5px;
}
.be-tabs{
justify-content: space-between;
}
.se-export{
justify-content: end;
margin-top: 3%;

.sos-export-col{
  padding-left: 10px;
  padding-right: 10px;
}
}

.sos-filters{
margin-left: -6px;
margin-right: -12px;
}
.sos-filters2 {
margin-top: 12px
}
.sos-viewBy{
display: flex;

.sos-compititor{
  margin-right: 10px;
}
}
.bubble-label{
margin-left: 15px;
margin-right: 10px;
}