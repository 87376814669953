.nutro-table{
    .ant-table-thead > tr > th {
        color: #fff;
        background-color: #0000A0;
        border: solid 0.1px #fff;
        line-height: 20%;
        font-size: 12px;
        font-weight: 400;
        font-weight: 700;
    }
    .ant-table-cell{
        font-size: 12px;
        font-weight: bold;
        // font-weight: normal;
        color: #000;
        text-align: center;
        word-break: break-all;
        overflow: hidden;
        padding-left: 0%;
        padding-right: 0%;
    }
    .ant-table-cell-with-append{
        text-align: left;
    }
}
