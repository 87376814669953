.admin-dash-container {
    .ant-table-cell {
        word-wrap: break-word;
        max-width: 300px;
    }
}
.export-button{
  position: absolute;
  right: 33px;
}
.filter-dropdown {
  padding: 2% 5%;
  max-height: 300px;
  overflow-y: auto;
  max-width: 240px;
  word-break: break-all;
}

.filter-buttons {
  padding: 3% 3%;
  display: flex;
  justify-content: space-evenly;
  .ant-btn-sm {
    margin: 5px;
  }
}
