.nudges-list {
    padding: 16px 24px;
    cursor: pointer;
    transition: all 0.3s ease-in;
    display: flex;
    align-items: center;

    .card-symbol {
        flex: 0 0 22px;
        margin: 0 12px 0 0;
    }
    
    &:not(:last-child) {
        border-bottom: 1px solid #F2F2F2;
    }

    h4 {
        font-size: 14px;
        line-height: 22px;
        font-weight: 400;
        color: #000000;
        margin: 0; 
    }

    &:hover {
        background: #F2F2F2;

        h4 {
            font-weight: 700;
            color: #0000A0;
        }
    }


}

.selected {
    background: #F2F2F2;

    h4 {
        font-weight: 700;
        color: #0000A0;
    }
}