.bar-container{
    background-color: white;
    border-radius: 12px;
    padding: 10px 10px;
    text-align: center;
    box-shadow: 0px 0px 42.6642px 0px rgba(0, 0, 0, 0.06);
    
    .section-title{
        display: flex;
        justify-content: space-between;
        .filter {
            text-align: left;
            display: flex;
            .filter-div{
                margin-right: 5%;
            }
            label {
                font-size: 12px;
                line-height: 16px;
                color: #9A9A9A; 
                margin: 0 0 4px;
            }
            
        }
        
    }
    width: 100%;
    // justify-content: center;
    // display: flex;
    // .bar-chart{
    //     margin: auto;
    //     width: 80%;
    //     height: 250px;
    // }
    .bar-charts{
        display: flex;
        justify-content: space-around;
        .bar-chart{
            width: 48%;
            .heading{
                font-size: 14px;
                font-weight: bold;
                color: #000;
            }
            .chart{
                height: 200px;
            }
        }
    }
}