.median-box {
  border: 1px solid #F2F2F2;
  border-radius: 10px;
  overflow: hidden;
  margin-top: 6px;
  
  h4 {
    margin: 0;
    line-height: 32px;
    text-align: center;
    background-color: #F2F2F2;
  }
  .inner-box {
    display: flex;

    div {
      flex: 0 0 50%;
      text-align: center;
      padding: 6px;
      + div {
        border-left: 1px solid #F2F2F2;
      }
    }
  }
}