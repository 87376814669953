@font-face {
  font-family: 'mars-centra-book';
  src: url('./../fonts/MarsCentra-Book.woff2') format('woff2'),
       url('./../fonts/MarsCentra-Book.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'mars-centra-bold';
  src: url('./../fonts/MarsCentra-Bold.woff2') format('woff2'),
       url('./../fonts/MarsCentra-Bold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'mars-centra-extrabold';
  src: url('./../fonts/MarsCentra-Extrabold.woff2') format('woff2'),
       url('./../fonts/MarsCentra-Extrabold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}