.container {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 0 1px 1px rgba(0,0,0,0.2);
  padding: 12px;
  margin-bottom: 4px;
  max-height: 13vh;
  height: 100%;

  .kpi1 {
    div {
      color: #000;
      font-weight: 700;
      font-size: 1.9vh;
    }
  }

  .kpi2 {
    font-size: 1.5vh;
    font-weight: 700;
    color: #808080;

    .negative {
      color: #ff0000
    }
    .positive {
      color: #008000
    }
  }
}