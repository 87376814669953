body {
  height: fit-content;
}

.loader-auth-container {
  margin: 16px;

  .loader-auth-card {
    display: flex;
    border-radius: 8px;
    background: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    height: 94vh;
    overflow: hidden;

    .loader-auth-left {
      display: flex;
      flex-direction: column;
      flex: 1;
      justify-content: center;
      align-items: center;

      .loader-auth-title {
        font-size: 32px;
        font-weight: 700;
      }

      .loader-auth-description {
        font-size: 24px;
        // font-weight: 700;
      }
    }

    .loader-auth-right {
      flex: 1;
    }
  }

  .loader-auth-card {
    flex-basis: 24%;
  }
}
