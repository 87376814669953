.diagnosticsdata-card {
    background: #fff;
    border-radius: 12px;
    padding: 20px 10px;
    text-align: center;
    box-shadow: 0px 0px 42.6642px 0px rgba(0, 0, 0, 0.06);

    .diagnosticsdata-head {
        // margin: 0 0 16px;
        // padding: 0 16px;
        position: relative;

        h4 {
            // color: #000;
            // font-size: 18px;
            // line-height: 24px;
            // margin: 0;
            color: #000;
            font-size: 18px;
            margin-top: 8px;
        }
        p {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 24px;
            font-weight: 500;
            font-family: "mars-centra-bold", Arial, sans-serif;
            color: #0000a0;
            margin-bottom: 8px;

            > span {
                font-size: 16px;
                font-weight: 400;
                color: #00c853;
                margin-left: 8px;
              }
              .positive {
                color: #00c853;
              }
              .negative {
                color: #ec3225;
              }
        }
        .d-check {
            top: 0;
            left: 0;
            position: absolute;
        }
        .d-more {
            top: -15px;
            right: 0;
            position: absolute;
        }
    }
    .diagnosticsdata-value {
        font-size: 32px;
        line-height: 74px;
        color: #0000A0;
        font-weight: bold;
    }
}