.outer-card-alerts {
    padding: 16px;
    background: #F2F2F2;
    border-radius: 10px;

    .nudges-card-title {
      font-size: 14px;
      line-height: 22px;
      font-weight: 700;
      color: #000000;
      position: relative;
      margin: 0 0 16px;
  
      span {
        background: #F2F2F2;
        display: inline-block;
        position: relative;
        padding-right: 8px;
      }

      &::before {
        content: '';
        height: 1px;
        width: 100%;
        background: #DCDCDC;
        display: block;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        z-index: 0;
      }
    }
    
    .inner-card {
      background: #fff;
      border-radius: 10px;
      display: flex;
      align-items: center;
    }
    .symbol{
        margin-left: 10px;
        min-width: 30px;
    }
    .card-header {
      color: #000000;
      display: flex;
      padding-top: 12px;
      font-size: inherit !important;
      font-weight: 500 !important;
      justify-content: flex-start !important;
      padding-bottom: 15px;
    }
  
    .card-data {
      justify-content: center;
      font-size: xxx-large;
      font-weight: bold;
      color: #0000a0;
    }
    .card{
      display: flex;
      justify-content: space-evenly;
    }
    .card-kpi{
        margin-bottom:20px;
        padding: 10px;
        border:2px solid lightgray;
        border-radius: 10px;
        width:35%;
    }
    .card-kpi-black {
      justify-content: center;
      color: #000000;
    }
  
    .card-kpi-red {
      justify-content: center;
      font-weight: bold;
      color: red;
    }
  
    .card-kpi-green {
      justify-content: center;
      font-weight: bold;
      color: green;
    }
  
    .button-div{
      display: flex;
      justify-content: center;
      margin-top: 20px;
    }
    .button{
      display: flex;
      justify-content: flex;
      border-radius: 5px; 
    }
  }
  
  h2 {
    color: #000000 !important;
  }


  