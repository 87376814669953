.header-data{
  margin-bottom: 16px;
}
.beVisible-card {
  background-color: white;
  margin-bottom: 16px;

  &::before {
    content: "";
    height: fit-content;
    background: #e71da1;
    width: 9px;
    min-height: 50px;
    border-radius: 5px;
    left: -10px;
    position: relative;
  }
  div {
    margin: 10px 0 0 0;
    font-size: 13px;
    span img {
      margin-top: -3px;
      margin-right: 3px;
    }
    b {
      font-size: 18px;
      font-weight: bolder;
      color: black;
    }
  }
}
.border-line {
  border-left: 1px;
  border: 1px solid lightgray;
}
.Filters-apply {
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 8px 12px;
  margin: 0 0 0px;
  background: #fafafa;

  .Report-selected-filters {
    display: flex;
    align-items: center;
    background: #fafafa;
    border-bottom: 0.5px solid #e0e0e0;
    padding-bottom: 7px;
    margin-bottom: 7px;

    label {
      color: rgba(0, 0, 0, 0.7);
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      margin-right: 8px;
    }

    .filter-item {
      background: rgba(0, 0, 160, 0.05);
      border-radius: 24px;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #0000a0;
      padding: 4px 12px;

      span {
        color: #000;
      }

      &:not(last-child) {
        margin-right: 10px;
      }
    }
  }
  .Keyword-selected-filters {
    display: flex;
    align-items: center;
    background: #fafafa;

    label {
      color: rgba(0, 0, 0, 0.7);
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      margin-right: 8px;
    }

    .filter-item {
      background: rgba(0, 0, 160, 0.05);
      border-radius: 24px;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #0000a0;
      padding: 4px 12px;

      span {
        color: #000;
      }

      &:not(last-child) {
        margin-right: 10px;
      }
    }
  }
}

.next-skus {
  margin-top: 10px;

  .ant-table-thead > tr > th {
    white-space: nowrap;
  }
  .ant-table-body .ant-table-cell {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  :has(.ant-table-filter-column) {
    padding-bottom: 5px;
  }
  .has-ant-table-columm-expand {
    padding-top: 0;
    padding-bottom: 0;
  }
  .ant-table-filter-column {
    align-items: center;
    margin-top: -10px;
  }
  .ant-table-filter-trigger {
    padding: 2px 4px;
  }
}

.retailer-view-toggle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;

  .ant-switch {
    display: block;
    background-image: none;
    background-color: #0000a0 !important;
  }
  .switch {
    margin: 0 10px;
  }
  .selected {
    color: #0000a0;
    font-weight: bold;
    background: none;
  }
  .unselected {
    color: #8f8f8f;
  }
}

.retailer-view-filter-right {
  display: flex;
}
.view-label{
  margin-right: 10px;
}
.ant-table-kpi {
  display: flex;
  justify-content: center;
  .ant-table-abs {
    white-space: nowrap;
    margin-left: 5px;
  }
  .ant-table-variance {
    white-space: nowrap;
    margin-left: 10px;
    margin-right: 5px;
  }
}
.searchIcon-main{
    display: flex;
    align-items: center;
  .searchVisible-icon{
    width: 22px;
    path{
      fill: #0000a0;
    }
  }
}
.pagination-style{
  float: right
}
.filter-title{
  width: 150px;
  margin-left: 50px;
}
.red-border{
  border: 1px solid red !important;
}
.required-field{
  margin-left: 10px;
  color: red;
}
.se-legends{
  display: flex;
  justify-content: space-between;

.searchTerm-list{
    display: grid;
    grid-template-columns: auto auto auto auto auto auto auto auto;
    gap: 0 35px;
    margin-top: 10px;

    li{
      list-style: none;
    }

    span{
      width: 8px;
      height: 8px;
      border-radius: 50%;
      display: inline-block;
    }
}
}

.seach-field{
  padding: 8px;

  .search-data{
    margin-bottom: 8;
    display: block;
  }

  .search-btn{
    width: 90px;
  }
}
.se-info{
  margin-right: 5px;
}
.se-tabs{
  justify-content: space-between;
}
.se-export{
  justify-content: end;
  margin-top: 3%;

  .export-col{
    padding: 0;
  }
}
.se-filters{
  display: flex;
  flex-direction: column;
  .keyword-category{
    width: 150px;
  }
  .keyword-filter{
    width: 113px;
  }
  .keyword-filter-second{
    width:90px
  }
  
}
.se-applyBtn{
  margin-top: 27%;
}
.se-viewBy{
  display: flex;
  align-items: center;
}
.se-bubbleLabel{
  margin-left: 15px;
  margin-right: 10px;
}
.nutro-sku-filter-description{
  font-size: 1.6vh !important;
}