.page-name {
  // font-weight: 700;
  // font-size: 18px;
  // line-height: 22px;
  // color: #7d7d7d;
  // position: relative;
  // display: flex;
  // align-items: center;
  // margin-left: 24px;

  ul {
    list-style: none;
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;

    > li {
      font-size: 18px;
      line-height: 22px;
      font-weight: 700;
      color: #7D7D7D;
      
      + li::before {
          content: ">";
          margin: 0 5px;
      }

      a {
        color: #7D7D7D;
        position: relative;
        // text-decoration: underline;
        &::after {
          content: '';
          width: 100%;
          height: 1px;
          display: block;
          background: #7D7D7D;
          left: 0;
          bottom: 0;
          position: absolute;
        }
      }
    }
  }
}
.diagnostics-card{
  background-color: white;
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;

  &::before{
      content: "";
      height: fit-content;
      background: orange;
      width: 9px;
      min-height: 50px;
      border-radius: 5px;
      // left: 31px;
      position: absolute;
      }
  div{
      margin: 10px 0 10px 20px;
      font-size: 13px;
      span img{
          margin-top: -3px;
          margin-right: 3px;
      }
      b{
          font-size: 18px;
          font-weight: bolder;
          color: black;
      }
  }    
}
.diagnostics-table{
  padding: 16px;
  background: #FFFFFF;
  box-shadow: 0px 0px 42.6642px 0px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
  margin-bottom: 16px;

  .nutro-table{
      width: 100%;
      div{
          position: relative;
          }
          .ant-table table {
            width: 100%;
            table-layout: auto !important;
            text-align: left;
            border-radius: 2px 2px 0 0;
            border-collapse: separate;
            border-spacing: 0;
        }
  }

  .nutro-table div h4{
  color: #000;
  font-size: 18px;
  line-height: 24px;
  margin: 0;
  text-align: center;
  }
}
.compitition-table{
  padding: 16px;
  background: #FFFFFF;
  box-shadow: 0px 0px 42.6642px 0px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
  margin-bottom: 16px;

  .nutro-table{
      width: 100%;
  }
  .nutro-table div h4{
      color: #000;
      font-size: 18px;
      line-height: 24px;
      margin: 0;
      text-align: center;
      }
  .table-dropdown{
      background: #e8e8e8;
      padding: 8px;
      margin-bottom: 20px;
      margin-top: 20px;
      border-radius: 6px;
      color: black;

      label {
        font-size: 12px;
        line-height: 16px;
        margin: 0 0 4px;
      }
  }
}
.diagnostics-chart{
  padding: 16px;
  background: #FFFFFF;
  box-shadow: 0px 0px 42.6642px 0px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
  margin-bottom: 16px;

  .kpiChart{
      position: relative;
      width: 100%;

      div h4{
          color: #000;
          font-size: 18px;
          line-height: 24px;
          margin: 0;
          text-align: center;
      }

      div p {
        color: #a7a3a3;
        font-size: 12px;
        text-align: center;
      }

      div .section-title-callout {
        font-size: 14px;
        color: #0000008a;
      }

      .d-more {
        top: 0;
        right: 0;
        position: absolute;
     }
  }
  .kpi-dropdown{
      display: flex;
      justify-content: end;
      margin-top: 25px;

      .kpi-labels{
        margin-left: 15px;
        margin-right: 10px;
      }
  }
}
.ecom-card{
  padding-left: 6px;
  padding-right: 6px;
  flex: 1 1 16%;
  max-width: 15%;
}

.next-skus {
margin-top: 10px;

.ant-table-thead > tr > th {
  white-space: nowrap;
}
.ant-table-body .ant-table-cell {
  padding-top: 8px;
  padding-bottom: 8px;
}
}
.dIcon-main{
  display: flex;
  align-items: center;
}
.dVisible-icon{
  width: 22px;
  path{
    fill: #0000a0;
  }
}
.kpi-text{
  text-align: center;
}
.alldropdown{
width: 100%;
display: flex;
justify-content: space-between;
}  
.other-dropdown{
display: flex;
}
.comparision-dropdown{
display: flex;
}