
.header-row{
    display: flex;
    width: 100%;
    padding-bottom: 10px;
    border-bottom: 1px solid lightgray;
    justify-content: space-between;
    .header-text{
        color: black;
        font-weight: 1000;
        font-size: larger;
    }
}

.data-tab{
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    margin-top: 16px;
    
    .data-header{
        padding: 15px;
        padding-bottom: 10px;
        color: black;
        font-weight: 1000;
        display: flex;
        justify-content: space-between;
    }
    .data-details{
        padding-left: 15px;
        padding-bottom: 6px;

        &:last-child {
            padding-bottom: 15px;
        }
    }
}
.export-row-width {
    width: calc(100% + 20px);
}

.d-left {
    border: 1px solid #EBEBEB;
    border-radius: 6px;
    padding: 16px;
    max-height: 100%;
    overflow: auto;

    h5 {
        font-size: 12px;
        line-height: 24px;
        color: #000000;
    }
    .ant-form-item-label {
        padding: 0 !important;

        label {
            font-size: 12px;
            line-height: 24px !important;
            color: #000000;
            opacity: 0.7;
        }
    }
    .ant-form-item {
        margin-bottom: 10px !important;
    }
    // .ant-select-multiple .ant-select-selector {
    //     height: 28px;
    // }
}
.ant-btn-text {
    border: none !important;
    background: none;
}
.ant-collapse {
    > .ant-collapse-item {
        > .ant-collapse-header {
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 18px !important;
            background: #F9F9F9;
            padding: 8px !important;
            display: flex;

            div {
                order: 2;
                margin-left: auto;
            }
        }
        .ant-collapse-content {
            .ant-collapse-content-box {
                padding-left: 0;
                padding-right: 0;
            }
        }
    }
}
.custom-modal {
    .ant-modal-header {
      display: flex;
      justify-content: center; 
      background-color: #fff; 
    }
  
    .custom-modal-title {
      color: black;
      font-weight: bold;
      text-align: center;
      width: 100%;
    }
  
    .ant-table-thead > tr > th {
      font-size: 16px; 
      padding: 12px; 
      white-space: nowrap;       // Prevent text wrapping
      overflow: hidden;          // Hide overflowed text  
      text-overflow: ellipsis;   // Display ellipsis for overflowed text
      max-width: 150px;          // Adjust width as needed
    }
  
    .ant-table-thead > tr > th,
    .ant-table-tbody > tr > td {
      text-align: center;        // Center align text
      padding: 8px;     
      
    height: 40px;
    // Add padding for better spacing
    }
  
    .ant-table-cell {
      padding: 8px;              // Add padding for table cells
    }

    .ant-table-content{
      max-height: 200px;
    }
  }


  
  .custom-table .ant-checkbox-wrapper {
    .ant-checkbox {
      width: 20px;
      height: 20px;
    }
  
    .ant-checkbox-inner {
      width: 100%;
      height: 100%;
      border-radius: 50%; /* Make the checkbox circular */
    }
  
    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: #0000a0; /* Customize the color if needed */
      border-color: #0000a0; /* Customize the color if needed */
    }
  
    .ant-checkbox-inner::after {
      width: 12px;
      height: 12px;
      border-radius: 50%; /* Make the checkmark circular */
      background-color: white; /* Color of the checkmark */
    }
  }
  .table-actions {
    display: flex;
    justify-content: flex-end; /* Align buttons to the right */
    margin-top: 20px; /* Space between the table and the buttons */
  
    button {
      font-weight: bold;
    }
  }
  .custom-modal {
    .save-filter-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      height: 100%;
      padding: 20px;
  
      h2 {
        color: black;
        text-align: center;
        margin-bottom: 20px;
      }
  
      .save-filter-input {
        width: 100%;
        padding: 10px;
        font-size: 16px;
        margin-bottom: 20px;
        border: 1px solid #ccc;
        border-radius: 4px;
      }
  
      .save-filter-button {
        margin-top: auto; /* Pushes the button to the bottom */
        width: 100%;
        height: 40px;
        font-size: 16px;
        font-weight: bold;
      }
    }
  }
.filter-button {
    margin-right: 10px;
    border-color: #0000b0;
    color: #0000a0;
    background-color: transparent;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        margin-right: 5px;
        width: 25px;
        height: 25px;
    }
}
