.user-profile-info-wrap {
    background: #F9F9F9;
    display: flex;
    align-items: center;
    padding: 16px;
    border-radius: 16px;
    margin: 0 0 26px;

    .user-profile-image {
        margin: 0 40px 0 0;
    }
    .user-profile-info {
        h4 {
            font-weight: 700;
            font-size: 18px;
            line-height: 22px;
            color: #000000;
            margin: 0 0 4px;
        }
        p {
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
            color: #000000;
            margin: 0;
        }
    }
}
// .profile{.ant-avatar-image {
//     background: transparent;
//     padding-top: 10px;
//     height: 100% !important;
//     width: 100% !important;
//     font-size: xx-large;
// }
// }

.report-tab {
    padding: 20px 16px;
    margin-bottom: 20px;
    box-shadow: 0px 4px 12px rgba(176, 176, 176, 0.25);

    .report-header {
        margin-bottom: 8px;
        color: #000000;
        display: flex;
        align-items: center;
        justify-content: space-between;

        h4 {
            cursor: pointer;
    
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
            margin: 0;
            color: #000000;
        }
    }
    .report-details {
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #525252;
        margin: 0;

        + .report-details {
            margin-top: 8px;
        }
    }
}

.ant-col-md-2 {
    display: block;
    flex: 0 0 8.33333333%;
    max-width: 6.333333% !important;
}

.no-data{
    display: flex;
    font-size: 20px;
    height: 200px;
    justify-content: center;
    align-items: center;
    /* vertical-align: middle; */
    // margin-left: 8px;
        }

// img {
//     display: block;
//     max-width: 100%;
//     width: 100%;
//     height: 100%;
//     object-fit: cover;
// }