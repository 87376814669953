.mytextdiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.mytexttitle {
  flex-grow: 0;
  font-size: initial;
  padding-right: 5px;
  font-weight: 900;
}

.divider {
  flex-grow: 1;
  height: 1px;
  width: 10%;
  background-color: #9f9f9f;
  opacity: 30%;
}

.ant-form-item {
  margin-bottom: 16px !important;
}